import { toast } from "react-toastify";

export const marriageVideo = [
  {
    groom: "Maurice",
    bride: "Tammy",
    year: 2024,
    videoId: "JxUltmcVwGg",
    place: "Jamaica",
  },
];

export const handleCopyText = (a) => {
  let alertText = a;
  navigator.clipboard.writeText(alertText);
  document.getSelection().removeAllRanges();

  toast.success("Phone Number Copied.", {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
