export const pkg = [
  {
    title: "Coconut Package",
    img: "/img/coconut-package.jpg",
    content: [
      "Coverage time: 6 hours",
      "Around 480 high resolution photos in JPG format with rights free for personal use in LINK.",
    ],
    price: "$4,287.87 + Tax",
  },
  {
    title: "Sand Package",
    img: "/img/sand-package.jpg",
    content: [
      "Coverage time: 5 hours",
      "Around 360 high resolution photos in JPG format with rights free for personal use in LINK.",
    ],
    price: "$3,294.99 + Tax",
  },
  {
    title: "Seashell Package",
    img: "/img/seashell-package.jpg",
    content: [
      "Coverage time: 4 hours",
      "Around 300 high resolution photos in JPG format with rights free for personal use in LINK.",
    ],
    price: "$2,767.79 + Tax",
  },
  {
    title: "Starfish Package",
    img: "/img/starfish-package.jpg",
    content: [
      "Coverage time: 3 hours",
      "Around 240 high resolution photos in JPG format with rights free for personal use in LINK.",
    ],
    price: " $2,187.87 + Tax",
  },
  {
    title: "Piña Package",
    img: "/img/piña-package.jpg",
    content: [
      "Coverage time: 2 hours",
      "Around 200 high resolution photos in JPG format with rights free for personal use in LINK.",
    ],
    price: "$1,739.75 + Tax",
  },
  {
    title: "Island Package",
    img: "/img/island-package.jpg",
    content: [
      "Coverage time: 1 hour and 30 min",
      "Around 110 high resolution photos in JPG format with rights free for personal use in LINK.",
    ],
    price: "$1,318.00 + Tax",
  },
  {
    title: "Engagement Package",
    img: "/img/engagement-package.jpg",
    content: [
      "Ideal package for a rehearsal dinner or if you want to surprise your partner at the moment you pop the question.",
      "Coverage time: 1 hour",
      "Around 80 high resolution photos in JPG format with rights free for personal use in LINK.",
      "Not valid as a wedding package.",
      "*Please read the Terms and Conditions section.",
    ],
    price: "$817.15 + Tax",
  },
];
